import React from 'react';
import { FaFacebook, FaAirbnb, FaInstagram } from 'react-icons/fa';
import './footer.scss';
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="container">
        <div className="social-links">
          <a
            href="https://www.facebook.com/rovorochrum/photos/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.instagram.com/rovorochrum/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram />
          </a>
          <a href="https://www.airbnb.se/users/show/188336070" 
             target="_blank" 
             rel="noreferrer">

            <FaAirbnb />
          </a>
        </div>

        <div className="text">
          <p>Copyright © Rovor & Rum</p>
          <p>
            {t('We do not store your information. We only use cookies to improve your experience on our website. By using our website, you consent to the use of cookies.')}
          </p>
          <p>
            Made by Yemeri, with{" "}
            <span role="img" aria-label="heart">
              ❤️
            </span>
            !
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
