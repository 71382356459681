import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./navbar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { useTranslation } from "react-i18next";

function Navbar() {
  const [click, setClick] = useState(false);
  const { t } = useTranslation();

  const handleClick = () => setClick(!click);
  
  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            Rovor & Rum
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >{t('Home')}
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/apartments"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                {t('Apartments')}
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                {t('About')}
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/destinations"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                {t('Destinations')}
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/activities"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                {t('Activities')}
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                {t('Contact')}
              </NavLink>
            </li>
       
            <LanguageSelector />

          </ul>

          <div className="nav-icon" onClick={handleClick}>
            <FontAwesomeIcon icon={click ? faTimes : faBars} />
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
