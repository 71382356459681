import React from 'react';
import Room1 from '../img/first-page_1.JPG';
import Room2 from '../img/first-page_2.JPG';
import Room3 from '../img/first-page_3.jpg';
import Room4 from '../img/first-page_4.JPG';
import Room5 from '../img/first-page_5.JPG';
import Room6 from '../img/first-page_6.JPG';
import { useTranslation } from "react-i18next";

import '../styles/home.scss'

const Home = () => {

const { t } = useTranslation();

return (
	<div className='main'>
		<section className='hero'>
			<h1 className='text'>
				<span>Rovor & Rum</span>
			</h1>
			<h2 className='text-small'>
				{t('Book Your Stay With Us')}
			</h2>
		</section>

		<section className='welcome'>
		<div className='text-container'>
		<h3>
		{t('Welcome to Rovor & Rum!')}
		</h3>
		</div>
		<div className='card-container'>
      <div className='card'>
        <img src={Room1} alt='room1' />
      </div>
      <div className='card'>
        <img src={Room2} alt='room2' />
      </div>
      <div className='card'>
        <img src={Room3} alt='room3' />
      </div>
	  <div className='card'>
        <img src={Room4} alt='room4' />
      </div>
	  <div className='card'>
        <img src={Room5} alt='room5' />
      </div>
	  <div className='card'>
        <img src={Room6} alt='room6' />
      </div>
    </div>
	</section>

	</div>
);
};

export default Home;
