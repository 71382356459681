import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Sweden from './Sweden.png';
import Germany from './Germany.png';
import UK from './UK.png';
import './LanguageSelector.scss';

const flags = [
  {
    src: Sweden,
    alt: 'Swedish',
    language: 'sv',
  },
  {
    src: UK,
    alt: 'English',
    language: 'en',
  },
  {
    src: Germany,
    alt: 'German',
    language: 'de',
  },
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [selectedFlag, setSelectedFlag] = useState(flags.find(flag => flag.language === i18n.language) || flags[0]);

  const changeLanguage = (language, flag) => {
    i18n.changeLanguage(language);
    setSelectedFlag(flag);
  };

  const [active, setActive] = useState(false);

  const toggleActive = () => {
    setActive(!active);
  };


  return (
    <div
      className={`language-selector${active ? ' active' : ''}`}
      onFocus={toggleActive}
      onBlur={toggleActive}
    >
      <div className='current-flag'>
      <img
        src={selectedFlag.src}
        alt={selectedFlag.alt}
        onClick={toggleActive}
      />
      </div>
      
      <div className="flags-container">
        <div className='flag'>
        {flags.map(flag => (
          <img 
            key={flag.language}
            src={flag.src}
            alt={flag.alt}
            onClick={() => {
              changeLanguage(flag.language, flag);
              toggleActive();
            }}
          />
        ))}
        </div>
      </div>
    </div>

  );
};

export default LanguageSelector;
